<template>
    <div>
    <!-- {{ currentTab() }} -->
        <section class="px-0 flex flex-column">
            <header-tab :tabs="[tabs[0].title, tabs[1].title, tabs[2].title]" @tab="toggleTabs" @defaulttab="currentTab()"
                class="mt-8"></header-tab>
            <!-- <div class="w-full">
                <ul class="nav-dash-tab w-full">
                    <li class="text-center font-bold uppercase" v-for="(tab, i) in tabs" :key="i"
                        v-on:click="toggleTabs(i + 1)"
                        v-bind:class="{ 'nav-dash-tab-item': openTab !== (i + 1), 'nav-dash-tab-item-active': openTab === (i + 1) }">
                        {{ tab.name }}
                    </li>
                </ul>
            </div> -->

            <div class="flex flex-col w-full mt-4">
                <div class="tab-content tab-space">
                    <div v-bind:class="{ 'hidden': openTab !== 1, 'block': openTab === 1 }">
                        <service-data-table :isProduct="false"></service-data-table>
                    </div>
                    <div v-bind:class="{ 'hidden': openTab !== 2, 'block': openTab === 2 }">
                        <service-data-table :isProduct="true"></service-data-table>
                    </div>
                    <div v-bind:class="{ 'hidden': openTab !== 3, 'block': openTab === 3 }">
                        <new-services></new-services>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import NewServices from '@/views/dashboard/services/NewServices.vue'
import ServiceDataTable from '@/views/dashboard/services/ServiceDataTable.vue'
import HeaderTab from '@/views/dashboard/components/HeaderTab.vue'

export default {
    components: {
        ServiceDataTable,
        NewServices,
        HeaderTab
    },
    data() {
        return {
            headers: {
                titlepage: 'SERVICES & PRODUCT',
                icon: null,
            },
            oldTab: null,
            loading_rfp_list: true,
            serviceDatas: [],

            openTab: this.currentTab(),

            tabs: [
                { title: "Services", link: { name: 'company.service.list' } },
                { title: "Products", link: { name: 'company.service.list' } },
                { title: "+ Add", link: { name: 'company.service.new' } },
            ],
        }
    },

    methods: {
        toggleTabs(tabNumber) {
            this.openTab = tabNumber
        },
        currentTab(){
            return window.location.href.includes('edit')? 3: 1
        }
    },
    beforeUpdate() {

    },
    beforeMount() {
        this.openTab = this.currentTab()
        // this.$router.push({ name: 'company.service.list' });
        // if ((this.$route.name.split(".")[2] === 'edit') || (window.location.href.includes('edit'))) {
        //     this.openTab = 3
        // }
        // else {
        //     this.openTab = 1
        // }
    },

    setup(props) {
        const tabdata = ref()

        return {
            tabdata,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/styles/tabs.scss';


.avatar-center {
    top: -50%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}
</style>
